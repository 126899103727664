<template>
  <div
    v-if="variants.v1 === variant"
    class="tw-flex tw-flex-col tw-gap-6 tw-px-4 tw-py-9"
    :style="`background-color: var(--c-${vars.backgroundColor}); border-radius: var(--rounded);`"
  >
    <h4>{{ vars.titleText }}</h4>

    <template v-if="vars.photoImage">
      <library-image :src="vars.photoImage" :design-width="423" :design-height="282" />
    </template>

    <template v-else-if="vars.videoUrl">
      <library-iframe :src="vars.videoUrl" class="tw-h-[282px] tw-w-full" />
    </template>

    <h5 v-if="vars.roleText">{{ vars.roleText }}</h5>

    <p v-if="vars.descriptionText">{{ vars.descriptionText }}</p>

    <div class="tw-mt-2 tw-flex tw-flex-col tw-gap-4 lg:tw-flex-row lg:tw-gap-4">
      <nuxt-link
        v-for="(button, index) in buttons"
        :key="`card-btn-${index}`"
        :class="`n-${button.type} tw-w-fit`"
        :to="button.link"
        :external="true"
      >
        {{ button.label }}
      </nuxt-link>
    </div>
  </div>
  <div
    v-if="variants.v2 === variant"
    class="tw-flex tw-flex-col tw-gap-6 tw-px-4 tw-py-9"
    :style="`background-color: var(--c-${vars.backgroundColor}); border-radius: var(--rounded);`"
  >
    <h4>{{ vars.titleText }}</h4>

    <template v-if="vars.photoImage">
      <library-image :src="vars.photoImage" :h-ratio="1" :w-ratio="1" />
    </template>
    <template v-else-if="vars.videoUrl">
      <library-iframe :src="vars.videoUrl" class="tw-h-[282px] tw-w-full" />
    </template>

    <h5 v-if="vars.roleText">{{ vars.roleText }}</h5>

    <p v-if="vars.descriptionText">{{ vars.descriptionText }}</p>

    <div class="tw-mt-2 tw-flex tw-flex-col tw-gap-4 lg:tw-flex-row lg:tw-gap-4">
      <nuxt-link
        v-for="(button, index) in buttons"
        :key="`card-btn-${index}`"
        :class="`n-${button.type} tw-w-fit`"
        :to="button.link"
        :external="true"
      >
        {{ button.label }}
      </nuxt-link>
    </div>
  </div>
</template>

<script lang="ts">
import { ComponentMixin } from '~/mixins/component.mixin';

export default defineNuxtComponent({
  name: 'MeetCard',

  mixins: [ComponentMixin],

  data() {
    return {
      variants: {
        v1: 'v1',
        v2: 'v2',
      },
    };
  },

  computed: {
    variant() {
      return useVariant(this.component);
    },

    buttons() {
      return this.groupedVariables.buttons;
    },

    descriptions() {
      return this.groupedVariables.descriptions;
    },
  },
});
</script>
