<template>
  <div
    class="guide-card shine-effect tw-group tw-relative tw-flex tw-h-full tw-w-full tw-cursor-pointer tw-flex-col tw-justify-start tw-gap-5 tw-text-start"
  >
    <div class="tw-relative">
      <LibraryImage
        :src="guide.photo"
        :h-ratio="1.4"
        :w-ratio="1"
        class="tw-overflow-hidden tw-rounded-[var(--rounded)]"
        @click="$emit('open-guide-popup')"
      />
      <div
        class="shine tw-absolute tw-inset-0 tw-bg-black tw-opacity-0 tw-transition-opacity tw-duration-500 group-hover:tw-opacity-50"
        style="border-radius: var(--rounded)"
      ></div>
      <div
        class="tw-pointer-events-none tw-absolute tw-bottom-3 tw-left-0 tw-right-0 tw-z-20 tw-flex tw-translate-y-full tw-transform tw-flex-col tw-items-center tw-gap-5 tw-overflow-hidden tw-p-4 tw-text-center tw-opacity-0 tw-transition-all tw-duration-500 group-hover:tw-pointer-events-auto group-hover:tw-translate-y-0 group-hover:tw-opacity-100"
      >
        <p
          v-if="guide.guide"
          :class="`n-${guide.button_type} !tw-font-bold tw-text-white`"
          @click="$emit('open-guide-popup')"
        >
          {{ guide.button_label }}
        </p>
        <nuxt-link v-else :external="true" :to="guide.button_link" :class="`n-${guide.button_type}`">{{
          guide.button_label
        }}</nuxt-link>
      </div>
    </div>
    <div class="tw-flex tw-h-full tw-flex-col tw-items-start tw-justify-start tw-gap-4 tw-text-start">
      <div>
        <h6 class="tw-line-clamp-2">{{ guide.title }}</h6>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { ComponentMixin } from '~/mixins/component.mixin';

export default defineNuxtComponent({
  name: 'Theme6GuideCard',

  mixins: [ComponentMixin],

  props: {
    guide: {
      type: Object,
      required: true,
    },

    index: {
      type: Number,
      required: true,
    },
  },
});
</script>

<style scoped>
.guide-card {
  transition: all 0.3s;
}

.guide-card:hover {
  transform: scale(0.98);
}
</style>
