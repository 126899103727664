<template>
  <template v-if="sidebarComponents.length === 0">
    <section
      v-if="variants.v1 === variant"
      class="n-section-primary"
      :style="`background-color: var(--c-${vars.backgroundColor}); color: ${vars.textColor ? `var(--c-${vars.textColor})` : 'var(--c-text-primary)'}`"
    >
      <div class="tw-flex tw-w-full tw-flex-col tw-gap-x-20 xl:tw-flex-row">
        <div
          class="tw-mb-10 tw-flex tw-flex-col tw-items-start tw-gap-3 tw-self-start tw-text-start lg:tw-mb-16 xl:tw-w-[42%]"
        >
          <small v-if="vars.altTitleText"> {{ vars.altTitleText }}</small>
          <h2>{{ vars.titleText }}</h2>
          <div class="tw-mt-4 tw-hidden xl:tw-block">
            <nuxt-link
              :external="true"
              :class="`n-${vars.buttonTypeText} n-btn`"
              :to="vars.buttonLinkUrl"
              v-if="vars.buttonLinkUrl"
            >
              <div class="n-btn-container">
                <div class="n-btn-group">
                  <div class="n-btn-t1">{{ vars.buttonLabelText }}</div>
                  <div class="n-btn-t2">{{ vars.buttonLabelText }}</div>
                </div>
              </div>
            </nuxt-link>
          </div>
        </div>
        <MotionGroup :preset="vars.animationText ? vars.animationText : 'slideVisibleBottom'" :duration="700">
          <Swiper
            effect="fade"
            :clickable="true"
            :modules="[SwiperGrid]"
            :watch-overflow="true"
            @slideChange="onSlideChange"
            :breakpoints="{
              320: {
                slidesPerView: 1,
              },
              768: {
                slidesPerView: 2,
                spaceBetween: 30,
              },
              1024: {
                slidesPerView: 3,
                spaceBetween: 30,
              },
              1280: {
                slidesPerView: 3,
                spaceBetween: 20,
                grid: { rows: 2, fill: 'row' },
              },
            }"
            :spaceBetween="5"
            @swiper="onSwiper"
            class="tw-w-full"
          >
            <SwiperSlide v-for="(guide, index) in guides" :key="`ourguide-guide-${index}`">
              <nuxt-link class="n-link" :to="guide.guide_link">
                <guide-card
                  :guide="guide"
                  :index="index"
                  :variant="variants.v1"
                  :component="component"
                  @click="toggleGuidePopup(guide, true)"
                />
              </nuxt-link>
            </SwiperSlide>
          </Swiper>
        </MotionGroup>
        <div class="tw-mt-4">
          <LibrarySliderPagination
            :swiper="swiper"
            variant="v4"
            :can-paginate-back="canPaginateBack"
            :can-paginate-next="canPaginateNext"
          />
        </div>
        <nuxt-link
          :external="true"
          :class="`n-${vars.buttonTypeText} n-btn tw-mt-8 tw-self-center xl:!tw-hidden`"
          :to="vars.buttonLinkUrl"
          v-if="vars.buttonLinkUrl"
        >
          <div class="n-btn-container">
            <div class="n-btn-group">
              <div class="n-btn-t1">{{ vars.buttonLabelText }}</div>
              <div class="n-btn-t2">{{ vars.buttonLabelText }}</div>
            </div>
          </div>
        </nuxt-link>
      </div>

      <popup v-if="selectedGuide && popupConfig.isActive" :config="popupConfig" custom-width="90%">
        <template #content>
          <div class="tw-w-full">
            <div
              class="tw-mx-auto tw-flex tw-w-full tw-flex-col-reverse md:tw-w-[80%] lg:tw-w-full lg:tw-flex-row"
              style="border-radius: var(--rounded)"
            >
              <div class="tw-flex tw-w-full tw-flex-col tw-p-6 md:tw-p-10 xl:tw-px-20">
                <small v-if="vars.altTitleText"> {{ selectedGuide.popup_alt_title }}</small>
                <h2 class="tw-mb-5">{{ selectedGuide.popup_title }}</h2>
                <library-form
                  v-if="selectedGuide.guide"
                  :form="parseFormV2(selectedGuide.guide)"
                  version="v2"
                  :component="component"
                  :is-form-name-visible="false"
                ></library-form>
              </div>
              <div class="tw-mt-5 tw-w-full lg:tw-max-w-[465px]">
                <LibraryImage
                  :w-ratio="1"
                  :h-ratio="1.45"
                  :src="selectedGuide.popup_cover"
                  style="border-radius: var(--rounded)"
                />
              </div>
            </div>
          </div>
        </template>
      </popup>
    </section>

    <section
      v-if="variant === variants.v2"
      class="n-section-primary tw-overflow-hidden"
      :style="`background-color: var(--c-${vars.backgroundColor}); color: ${vars.textColor ? `var(--c-${vars.textColor})` : 'var(--c-text-primary)'}`"
    >
      <div
        class="tw-relative tw-flex tw-w-full tw-max-w-[600px] tw-flex-col tw-items-center tw-gap-x-20 tw-gap-y-2 lg:tw-max-w-none lg:tw-flex-row xl:tw-gap-x-32"
      >
        <div
          class="tw-mt-10 tw-flex tw-flex-col tw-items-center tw-self-center tw-text-center lg:tw-items-start lg:tw-self-start lg:tw-text-start"
        >
          <h2 class="lg:tw-w-[300px] 2xl:tw-w-[400px]">{{ vars.titleText }}</h2>
          <div class="tw-hidden lg:tw-block">
            <LibrarySliderPagination
              :swiper="swiper"
              variant="v5"
              :can-paginate-back="canPaginateBack"
              :can-paginate-next="canPaginateNext"
            />
          </div>
        </div>
        <div class="tw-w-full">
          <MotionGroup :preset="vars.animationText ? vars.animationText : 'popVisible'" :duration="800">
            <Swiper
              :modules="[SwiperPagination]"
              @slideChange="onSlideChange"
              :pagination="false"
              :space-between="20"
              :loop="true"
              effect="fade"
              :clickable="true"
              :breakpoints="{
                320: {
                  slidesPerView: 1,
                },
                1024: {
                  slidesPerView: 4,
                },
                1536: {
                  spaceBetween: 40,
                  slidesPerView: 4,
                },
              }"
              @swiper="onSwiper"
              class="lg:tw-w-[105%] 2xl:tw-w-full"
            >
              <SwiperSlide v-for="(guide, index) in guides" :key="`ourguide-guide-${index}`">
                <guide-card
                  :guide="guide"
                  :index="index"
                  :variant="variants.v2"
                  :component="component"
                  @click="toggleGuidePopup(guide, true)"
                />
              </SwiperSlide>
            </Swiper>
          </MotionGroup>
        </div>
        <div class="lg:tw-hidden">
          <LibrarySliderPagination
            :swiper="swiper"
            variant="v5"
            :can-paginate-back="canPaginateBack"
            :can-paginate-next="canPaginateNext"
          />
        </div>
      </div>

      <popup v-if="selectedGuide && popupConfig.isActive" :config="popupConfig" custom-width="90%">
        <template #content>
          <div class="tw-w-full">
            <div
              class="tw-mx-auto tw-flex tw-w-full tw-flex-col tw-bg-white md:tw-w-[80%] lg:tw-w-full lg:tw-flex-row"
              style="border-radius: var(--rounded)"
            >
              <div class="tw-w-full lg:tw-max-w-[465px]">
                <LibraryImage
                  :w-ratio="1"
                  :h-ratio="1.45"
                  :src="selectedGuide.popup_cover"
                  style="border-radius: var(--rounded)"
                />
              </div>
              <div class="tw-flex tw-w-full tw-flex-col tw-p-6 md:tw-p-10 xl:tw-px-20">
                <h2>{{ selectedGuide.popup_title }}</h2>
                <library-form
                  v-if="selectedGuide.guide"
                  :form="parseFormV2(selectedGuide.guide)"
                  version="v2"
                  :component="component"
                  :is-form-name-visible="false"
                ></library-form>
              </div>
            </div>
          </div>
        </template>
      </popup>
    </section>
  </template>

  <template v-else-if="sidebarComponents.length > 0">
    <div
      v-if="variants.v1 === variant"
      class="n-section-primary"
      :style="`background-color: var(--c-${vars.backgroundColor}); color: ${vars.textColor ? `var(--c-${vars.textColor})` : 'var(--c-text-primary)'}`"
    >
      <div class="tw-flex tw-w-full tw-flex-col tw-gap-x-20 xl:tw-flex-row">
        <div
          class="tw-mb-10 tw-flex tw-flex-col tw-items-start tw-gap-3 tw-self-start tw-text-start lg:tw-mb-16 xl:tw-w-[42%]"
        >
          <small v-if="vars.altTitleText"> {{ vars.altTitleText }}</small>
          <h2>{{ vars.titleText }}</h2>
          <div class="tw-mt-4 tw-hidden xl:tw-block">
            <nuxt-link
              :external="true"
              :class="`n-${vars.buttonTypeText} n-btn`"
              :to="vars.buttonLinkUrl"
              v-if="vars.buttonLinkUrl"
            >
              <div class="n-btn-container">
                <div class="n-btn-group">
                  <div class="n-btn-t1">{{ vars.buttonLabelText }}</div>
                  <div class="n-btn-t2">{{ vars.buttonLabelText }}</div>
                </div>
              </div>
            </nuxt-link>
          </div>
        </div>
        <MotionGroup :preset="vars.animationText ? vars.animationText : 'slideVisibleBottom'" :duration="700">
          <Swiper
            effect="fade"
            :clickable="true"
            :modules="[SwiperGrid]"
            :watch-overflow="true"
            @slideChange="onSlideChange"
            :breakpoints="{
              320: {
                slidesPerView: 1,
              },
              768: {
                slidesPerView: 2,
                spaceBetween: 30,
              },
              1024: {
                slidesPerView: 3,
                spaceBetween: 30,
              },
              1280: {
                slidesPerView: 3,
                spaceBetween: 20,
                grid: { rows: 2, fill: 'row' },
              },
            }"
            :spaceBetween="5"
            @swiper="onSwiper"
            class="tw-w-full"
          >
            <SwiperSlide v-for="(guide, index) in guides" :key="`ourguide-guide-${index}`">
              <nuxt-link class="n-link" :to="guide.guide_link">
                <guide-card :guide="guide" :index="index" :variant="variants.v1" :component="component" />
              </nuxt-link>
            </SwiperSlide>
          </Swiper>
        </MotionGroup>
        <div class="tw-mt-4">
          <LibrarySliderPagination
            :swiper="swiper"
            variant="v4"
            :can-paginate-back="canPaginateBack"
            :can-paginate-next="canPaginateNext"
          />
        </div>
        <nuxt-link
          :external="true"
          :class="`n-${vars.buttonTypeText} n-btn tw-mt-8 tw-self-center xl:!tw-hidden`"
          :to="vars.buttonLinkUrl"
          v-if="vars.buttonLinkUrl"
        >
          <div class="n-btn-container">
            <div class="n-btn-group">
              <div class="n-btn-t1">{{ vars.buttonLabelText }}</div>
              <div class="n-btn-t2">{{ vars.buttonLabelText }}</div>
            </div>
          </div>
        </nuxt-link>
      </div>
    </div>

    <div
      v-if="variant === variants.v2"
      class="tw-overflow-hidden tw-px-4 tw-pb-9"
      :style="`background-color: var(--c-${vars.backgroundColor}); color: ${vars.textColor ? `var(--c-${vars.textColor})` : 'var(--c-text-primary)'}; border-radius: var(--rounded);`"
    >
      <div class="tw-relative tw-flex tw-w-full tw-flex-col tw-gap-x-20 tw-gap-y-2">
        <div class="tw-mt-10 tw-flex tw-flex-col">
          <h2>{{ vars.titleText }}</h2>
        </div>

        <div class="tw-w-full">
          <Swiper
            :modules="[SwiperPagination]"
            :pagination="false"
            :slides-per-view="1"
            :space-between="20"
            :loop="true"
            effect="fade"
            :clickable="true"
            @slideChange="onSlideChange"
            @swiper="onSwiper"
            class="tw-w-full"
          >
            <SwiperSlide v-for="(guide, index) in guides" :key="`ourguide-guide-${index}`">
              <guide-card
                :guide="guide"
                :index="index"
                :variant="variants.v2"
                :component="component"
                @open-guide-popup="toggleGuidePopup(guide, true)"
              />
            </SwiperSlide>
          </Swiper>
        </div>
        <div>
          <LibrarySliderPagination
            :swiper="swiper"
            variant="v5"
            :can-paginate-back="canPaginateBack"
            :can-paginate-next="canPaginateNext"
          />
        </div>
      </div>

      <popup v-if="selectedGuide && popupConfig.isActive" :config="popupConfig" custom-width="90%">
        <template #content>
          <div class="tw-w-full">
            <div
              class="tw-mx-auto tw-flex tw-w-full tw-flex-col tw-bg-white md:tw-w-[80%] lg:tw-w-full lg:tw-flex-row"
              style="border-radius: var(--rounded)"
            >
              <div class="tw-w-full lg:tw-max-w-[465px]">
                <LibraryImage
                  :w-ratio="1"
                  :h-ratio="1.45"
                  :src="selectedGuide.popup_cover"
                  style="border-radius: var(--rounded)"
                />
              </div>
              <div class="tw-flex tw-w-full tw-p-6 md:tw-p-10 xl:tw-px-20">
                <h2>{{ selectedGuide.popup_title }}</h2>
                <library-form
                  v-if="selectedGuide.guide"
                  :form="selectedGuide.guide"
                  version="v2"
                  :component="component"
                ></library-form>
              </div>
            </div>
          </div>
        </template>
      </popup>
    </div>
  </template>
</template>

<script lang="ts">
import { mapState } from 'pinia';
import GuideCard from './GuideCard.vue';
import Popup from '~/components/common/popup/Popup.vue';
import { ComponentMixin } from '~/mixins/component.mixin';
import { useWebsiteStore } from '~/units/website/store';

export default defineNuxtComponent({
  name: 'OurGuide',

  mixins: [ComponentMixin],

  components: {
    GuideCard,
    Popup,
  },

  data() {
    return {
      swiper: null,
      form: null,
      canPaginateBack: true,
      canPaginateNext: true,
      selectedGuide: null,
      popupConfig: {
        isActive: false,
      },
      variants: {
        v1: 'v1',
        v2: 'v2',
      },
    };
  },

  /* created() {
    this.form = this.parseFormV2('guide-form');
  }, */

  mounted() {
    this.onSlideChange();
  },

  computed: {
    ...mapState(useWebsiteStore, ['sidebarComponents']),

    guides() {
      return this.groupedVariables.guides;
    },
  },
  methods: {
    toggleGuidePopup(guide = null, value = false) {
      if (guide?.popup_title) {
        this.selectedGuide = guide;
        this.popupConfig.isActive = value;
      }
    },

    onSwiper(swiper) {
      this.swiper = swiper;
    },

    onSlideChange() {
      if (this.variant === this.variants.v1) {
        if (!this.swiper) return;
        const isAtStart = this.swiper.isBeginning;
        const isAtEnd = this.swiper.isEnd;
        if (isAtStart) this.canPaginateBack = false;
        else this.canPaginateBack = true;
        if (isAtEnd) this.canPaginateNext = false;
        else this.canPaginateNext = true;
      } else if (this.variant === this.variants.v2) {
        if (!this.swiper) return;
        this.canPaginateBack = true;
        this.canPaginateNext = true;
      }
    },
  },
});
</script>
